.text-center {
    text-align: center;
}

.col-4-grid {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 30px;
}

.col-12-grid {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(12, 1fr);
    grid-column-gap: 30px;
}

.col-8-grid {
    display: grid;
    width: 100%;
    margin: auto;
    grid-template-columns: repeat(8, 1fr);
    grid-column-gap: 30px;
}

.col-7-grid {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(7, 1fr);
    grid-column-gap: 30px;
}

.col-6-grid {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(6, 1fr);
    grid-column-gap: 30px;
}
.col-5-grid {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(5, 1fr);
    grid-column-gap: 30px;
}

.col-3-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-column-gap: 30px;
}
.col-2-grid {
    display: grid;
    margin: auto;
    grid-template-columns: repeat(2, 1fr);
    gap: 30px;
}


.span-12 {grid-column: auto / span 12;}
.span-11 {grid-column: auto / span 11;}
.span-10 {grid-column: auto / span 10;}
.span-9 {grid-column: auto / span 9;}
.span-8 {grid-column: auto / span 8;}
.span-7 {grid-column: auto / span 7;}
.span-6 {grid-column: auto / span 6;}
.span-5 {grid-column: auto / span 5;}
.span-4 {grid-column: auto / span 4;}
.span-3 {grid-column: auto / span 3;}
.span-2 {grid-column: auto / span 2;}
.span-1 {grid-column: auto / span 1;}


.container-1200 {
    width: 100%;
    max-width: 1100px;
    margin: auto;
    padding: 16px;
}

.final-rom {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 104px;
    padding: 32px;
    margin: auto;
}

.fun-controller {
    display: flex;
    align-items: flex-end;
}

.socials {
    gap: 4px;
    a {
        transition: 0.3s ease;
        border-radius: 32px;
    }

    a:hover {
        background-color: rgba(0,0,0, 0.1);
    }
}

.space-between {
    justify-content: space-between;
}


.not-found {
    width: 100%;
    height: calc(100vh - 113px);
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 50%;
    }
}
.fun-grid {
    margin-bottom: 32px;
    h3 {
        font-size: 18px;
        font-weight: 700;
    }
    ul {
        padding: 0;
        display: flex;
        flex-wrap: wrap;
        margin: 0;
        gap: 12px;

        li {
            list-style: none;
            padding: 4px 8px;
            width: 40px;
            height: 40px !important;
            display: flex;
            justify-content: center;
            align-items: center;
            height: min-content;
            border-radius: 32px;
            font-weight: 600;
            font-size: 24px;
            line-height: 100%;
            background-color: rgba(0,0,0, 0.04);
        }
    }
}

.fun-body {
    padding-top: 16px;
}
.form-controler {
    list-style-type: none;
    border-bottom: 1px solid var(--border);
    gap: 40px;
    display: flex;
    padding: 0;
    color: #777777;
    margin-top: 24px;
    
    input[type="radio"] {
        opacity: 0;
        display: none;
        z-index: 100;
    }

    input[type="radio"]:checked+label,
    .Checked+label {
        color: black;
        border-bottom: 4px solid black;
    }

    label {
        cursor: pointer;
        z-index: 90;
        position: relative;
        border-radius: 100px;
        font-weight: 700;
        color: #898989;
        color: inherit;
        text-decoration: none;
        padding: 16px 0 !important;
        font-size: 18px;
        border-radius: 0;
        transition: all 0.6s ease;
    }

    label:hover {
        color: black;
    }
}

.scroll-menu {
    position: fixed;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 16px;
    mix-blend-mode: exclusion;

    a:first-of-type {
        div {
            padding-top: 0;
        }
    }
    a:last-of-type {
        div {
            padding-bottom: 0;
        }
    }

    .ss-item {
        color: rgba(255,255,255, 0.4);
        font-size: 14px;
        font-weight: 600;
        padding: 6px;
        padding-left: 12px;
        transition: all 0.3s ease;
        border-left: 2px solid rgba(255, 255, 255, 0.2);
    }

    .ss-active {
        color: white;
        border-color: white;
    }

    @media screen and (max-width: 1220px) {
        mix-blend-mode: unset;
        top: unset;
        flex-direction: row;
        backdrop-filter: blur(70px);
        padding: 0px;
        background-color: #fff;
        z-index: 999;
        transition: all 0.3s ease;
        width: 100%;
        overflow: scroll;
        justify-content: flex-start;

        .ss-item {
            color: rgba(0, 0, 0, 0.4);
            font-size: 14px;
            word-break: keep-all;
            font-weight: 600;
            padding: 16px;
            width: max-content;
            transition: all 0.3s ease;
            border-left: 0;
            border-top: 2px solid rgba(0, 0, 0, 0.2);
        }

        .ss-active {
            color: black;
            border-color: black;
        }

        a:first-of-type {
            div {
                padding-top: 16px;
            }
        }

        a:last-of-type {
            div {
                padding-bottom: 16px;
            }
        }
    }
}

.men-whiteboarding {
    display: flex;
    align-items: center;
    justify-content: center;

    video{
    border-radius: 12px;
    }

    &.fixed-height {
        padding: 160px;
        margin-bottom: -120px;
    }
}

.marquee {
    overflow: visible;
    margin: 32px 0;
    margin-bottom: 120px;


    .rfm-child {
        width: 100%;

        img {
            height: 400px !important;
        }
    }
}

.justify-between {
    justify-content: space-between;
    width: 100%;
}

.case-study {
    .cs-header {
            width: 100%;
            max-width: 1200px;
            height: 550px;
            background-size: cover;
            margin: auto;
            margin-top: 110px;
            border-radius: 20px;
        }
    img {
        width: 100%;
    }
    
    .blurb {
        margin-bottom: 28px;
    }


    h1 {
        font-size: 40px;
        line-height: 150%;
        margin-bottom: 0;
    }

    h2 {
        font-size: 36px;
        font-weight: 500;
        line-height: 150%;
        margin-bottom: 20px;
    }

    p {
        font-size: 18px;
        line-height: 170%;
        font-weight: 600;
        color: var(--text-secondary) ;
    }

    h3 {
        font-size: 18px;
        line-height: 160%;
        font-weight: 800;
        color: var(--text);
        border-bottom: 12px;
        margin-bottom: 4px;
    }

    .list-section .span-3 {
        margin-bottom: 24px;
    }

    .col-8-grid {
        grid-column-gap: 0px;
    }

    .ver-section-title {
        h2:last-of-type {
            margin-bottom: 8px
        }
    }
    
    .case-info {
        padding: 16px;
        margin: 32px auto;
        margin-bottom: 160px;
        &.col-4-grid {
            column-gap: 64px;
        }
 
    }
    .list-section .span-1 {
        text-align: right;
    }

    .case-section-title-cont {
        
        h2 {
            font-weight: 800;
            line-height: 120%;
            color: black;
            margin-bottom: 0px;
        }

        h2:last-of-type {
            color: var(--text-secondary);
        }
    }

    .light-bg, .dark-bg {
        padding: 160px 0 100px 0;
    }

    .dark-bg {
        background-color: #252525;

        h2 {
            color: white;
        }

        h3 {
            color: white;
        }

        h2:last-of-type {
            color: rgba(255,255,255, 0.5);
        }

        p {
            color: rgba(255, 255, 255, 0.5);
        }
    }

    #final .finary-bg {
        margin-bottom: -120px;
    }

    .finary-bg {
        padding: 100px;
        height: 100vh;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .before-after {
        h4 {
            margin: 16px 0 0 !important;
            font-weight: 700;
            font-size: 18px;
            margin-top: 0 !important;
            margin-bottom: 4px !important;
            padding-top: 24px !important;
        }

        .span-1:nth-child(odd) {
            h4 {
                border-top: 8px solid #b3102e;
                color: #b3102e !important;
            }
         }


        .span-1:nth-child(even) {
            h4 {
                border-top: 8px solid #0c7354;
                color: #0c7354 !important;
            }

        }
    }

    .menti-intro-cont {
        position: relative;
        margin-top: -80px;
        .mento-video {
            position: absolute;
            width: 36.2%;
            top: 16.5%;
            left: 30.6%;

        }

        img {
        mix-blend-mode: multiply;
        }
    }

    .bsc-usertest {
        .span-2 {
            p {
                margin-bottom: 0;
            }
        }

        h3 {
            font-size: 24px;
        }
    }
    .bp-quote {
        max-width: 800px;
        padding: 16px;
        margin: auto;
        margin-bottom: 60px;

        h2 {
            font-size: 32px;
        }
    }

    .final-showcase {
        display: flex;
        flex-direction: column;
        justify-content: end;

        h2 {
            font-size: 24px;
            font-weight: 800;
            color: white !important;
            margin-bottom: 4px;
        }
    }

    .video-container {
        position: relative;
        padding-bottom: 36.25%;
        height: 0;

        iframe {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
        }
    }



    @media screen and (max-width: 820px) {
        .case-info {

            h2 {
                margin-bottom: -16px;
            }
            .span-3 {
                grid-column: auto / span 4;
            }

            &.col-4-grid {
                gap: 32px;
            }

            .tools.span-1 {
                grid-column: auto / span 4;
                display: grid;
                grid-template-columns: repeat(4, 1fr);
                gap: 30px;
                padding-top: 32px;
                border-top: 1px solid var(--border);

                div{
                grid-column: auto / span 2;
                }
            }
        }
    }

    @media screen and (max-width: 820px) {
        .col-8-grid {
            .span-5 {
            grid-column: auto / span 8;
                .span-1 {
                    text-align: left;
                }
            }

            .span-3 {
                grid-column: auto / span 8;
            }
        }

    }

    @media screen and (max-width: 575px) {
        .col-2-grid {
            .span-1 {
                grid-column: auto / span 2 !important;
            }

        }

        .h-left-side {
            h2:last-of-type {
                margin-bottom: 8px;
            }
        }

        .case-section-title-cont {
            margin-bottom: 0;
        }

        .light-bg, .dark-bg {
            padding-top: 100px;
        }

        .ver-section-title {
            margin-bottom: 20px;
        }
    }
}

@media screen and (max-width: 1232px) { 
    .case-study .cs-header {
        width: calc(100% - 32px);
        margin-top: 92px;
    }
}

.full-bleed-cont {
    padding: 32px;
    margin-top: 92px;

    h1 {
        font-size: 80px;
    }


}
@media screen and (max-width: 393px) { 
    .col-12-grid {
        gap: 0;
    }
}

@media screen and (max-width: 1160px) {

    .full-bleed-cont {
        .form-controler {
            margin-top: 8px;
        }
        .span-4 {
            grid-column: auto / span 12;
        }
        .span-8 {
            grid-column: auto / span 12;
        }
    }

    .final-rom {
        max-width: 1100px;
        padding: 16px;

        .final-showcase {
            margin-top: 32px;
        }

        .col-12-grid:nth-child(even) {
            .final-showcase {
                grid-row-start: 2;
            }

            
        }
        .col-12-grid:last-of-type {
            .final-showcase {
                grid-row-start: 4;
            }
        }

        .span-9 {
            grid-column: auto / span 12;
        }
        .span-3 {
            grid-column: auto / span 9;
        }
        .span-2 {
            grid-column: auto / span 3;
        }
        .span-7 {
            grid-column: auto / span 9;
        }
    }
}

@media screen and (max-width: 1160px) {

    .full-bleed-cont {
        padding: 16px;
    }
}
@media screen and (max-width: 780px) {
    .full-bleed-cont {
        .col-2-grid {
            display: block;
        }
    }
}

@media screen and (max-width: 630px) {

    .final-rom {
        .span-3 {
            grid-column: auto / span 12;
        }
    }
}

.mt-64 {margin-top: 64px;}
.mt-32 {margin-top: 32px;}
.mt-24 {margin-top: 24px;}
.mt-16 {margin-top: 16px;}

.mb-64 {margin-bottom: 64px;}
.mb-32 {margin-bottom: 32px;}
.mb-24 {margin-bottom: 24px;}
.mb-16 {margin-bottom: 16px;}