.password-page {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    right: 0;
    padding: 20px;
    background-color: #F1F1EE;
    z-index: 1000;
    text-align: center;
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
  

    .contents {
        max-width: 320px;
        gap: 8px;
        text-align: center;
        justify-content: center;
            align-items: center;
            display: flex;
            flex-direction: column;
    }

    h1 {
        font-size: 32px;
    }

    .lock {
        padding: 12px;
        border: 1px solid var(--border);
        width: min-content;
        border-radius: 12px;
    }
    p {
        margin-bottom: 8px !important;
    }

    .back-button  {
        position: absolute;
        top: 32px;
        left: 32px;
        padding: 12px;
        border: 1px solid var(--border);
        border-radius: 100px;
    }

    .password-form {
        position: relative;
        width: 100%;

        input {
            padding: 12px 16px;
            height: 48px;
            border-radius: 12px;
            border: 1px solid var(--border);
            display: block;
            width: 100%;
            margin-bottom: 12px;
        }

        button {
            display: block;
            background-color: black;
            color: #F1F1EE;
            border: none;
            height: 48px;
            padding: 8px;
            width: 100%;
            border-radius: 12px;
        }

        .error {
            position: absolute;
            top: 120%;
            width: 100%;
        }
    }
}