.top-navbar {
    justify-content: space-between;
    /* mix-blend-mode: exclusion; */
    position: fixed;
    top: 0;
    width: 100%;
    color: #898989;
    border-radius: 64px;
    padding: 32px;
    z-index: 999;
    transition: all 0.3s ease;

}
.top-navbar .navbar-collapse{
    text-align: right;
    justify-content: end;
    align-items: right;
    /* height: 100px; */
}

.top-navbar .navbar-nav {
    align-items: right;
    gap: 16px;
}

.navbar-brand {
    margin: 0 !important;
}

.active-nav {
    background-color: rgb(200 200 200 / 63%);
    &.nav-link {
        color: black !important; 
    }
}

.grad-blur {
    position: absolute;
    z-index: -1;
    inset: auto 0 0 0;
    height: 130%;
    top: 0;
    pointer-events: none;
}

.grad-blur>div,
.grad-blur::before,
.grad-blur::after {
    position: absolute;
    inset: 0;
}

.grad-blur::before {
    content: "";
    z-index: 1;
    backdrop-filter: blur(0.5px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 12.5%,
            rgba(0, 0, 0, 1) 25%,
            rgba(0, 0, 0, 0) 37.5%);
}

.grad-blur>div:nth-of-type(1) {
    z-index: 2;
    backdrop-filter: blur(1px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 12.5%,
            rgba(0, 0, 0, 1) 25%,
            rgba(0, 0, 0, 1) 37.5%,
            rgba(0, 0, 0, 0) 50%);
}

.grad-blur>div:nth-of-type(2) {
    z-index: 3;
    backdrop-filter: blur(2px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 25%,
            rgba(0, 0, 0, 1) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 0) 62.5%);
}

.grad-blur>div:nth-of-type(3) {
    z-index: 4;
    backdrop-filter: blur(4px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
}

.grad-blur>div:nth-of-type(4) {
    z-index: 5;
    backdrop-filter: blur(8px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 1) 75%,
            rgba(0, 0, 0, 0) 87.5%);
}

.grad-blur>div:nth-of-type(5) {
    z-index: 6;
    backdrop-filter: blur(16px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 62.5%,
            rgba(0, 0, 0, 1) 75%,
            rgba(0, 0, 0, 1) 87.5%,
            rgba(0, 0, 0, 0) 100%);
}

.grad-blur>div:nth-of-type(6) {
    z-index: 7;
    backdrop-filter: blur(32px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 75%,
            rgba(0, 0, 0, 1) 87.5%,
            rgba(0, 0, 0, 1) 100%);
}

.grad-blur::after {
    content: "";
    z-index: 8;
    backdrop-filter: blur(64px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 87.5%,
            rgba(0, 0, 0, 1) 100%);
}

.top-navbar .nav-link {
    font-weight: 700;
    color: #898989;
    /* background: linear-gradient(to bottom, #0087ca 0%, #0087ca 100%); */
    background-position: 0 100%;
    background-repeat: repeat-x;
    background-size: auto 4px;
    color: inherit;
    text-decoration: none;
    padding: 8px 16px !important;
    border-radius: 100px;
    font-size: 18px;
    transition: all 0.6s ease;
    
}

.top-navbar .nav-link:hover {
    background-color: rgb(200 200 200 / 63%);
    
        &.nav-link {
            color: black !important;
        }
}

.hidden-brand {
    display: none;
}
.shown-brand {
    display: block;
}

.navbar-toggler {
    border-radius: 32px;
    padding: 12px;
    box-shadow: none !important;
}


.shown-brand {
    display: block;
}

@media screen and (max-width: 991px) {
    .hidden-brand,
    .nav-button.toggle-mob {
        display: block;
    }

    .shown-brand {
        display: none;
    }

    .top-navbar {
        width: 100%;
        padding: 16px;
        padding-bottom: 20px;
    }

    .navbar-nav {
        .nav-link {
            text-align: right;
        }
    }
    

    .navbar-toggler {
        border: none;
    }
}