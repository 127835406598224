.articles {
    width: 100%;
    padding: 32px;
    margin-top: 140px;

    h1 {
            margin: 0 !important;
        }

}

@media screen and (max-width: 868px) {
    .articles {
        .span-5 {
            grid-column: auto / span 12;
        }

        .span-7 {
            grid-column: auto / span 12;
        }

        .col-7-grid {
            margin-top: 16px;
        }
    }
}