// $max-width: 650px;

// ------------------------------------------------------

.home-card-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 32px;
}

.project-card {
    &:hover {
        .preview-image {
            transform: scale(1.05) !important;
        }
    }

}

.fanout {

    .preview-image {
        width: 100%;
        padding-bottom: 65%;
        background-size: cover;
        display: block;
        background-color: #e2e2de;
        color: black;
        border-radius: 12px;
        transition: all 0.3s ease;
        background-position: center;
        transition: all 0.4s ease !important;

    }

    &:hover {
        transform: scale(1.05);
    }

    h3 {
        font-size: 28px;
        color: var(--title-secondary) !important;
        font-weight: 700;
        margin-bottom: 8px;
    }
    
    h3:first-of-type {
        font-size: 28px;
        color: var(--text) !important;
        font-weight: 700;
        margin-bottom: 4px;
        
    }

    p {
        font-size: 16px;
        color: var(--text-secondary);
        font-weight: 500;
        margin-bottom: 0;
    }

    .content {
        height: 100%;
    }

    .rom-gif {
        height: 120%;
        position: absolute;
        border: 4px solid black;
        left: 20%;
        top: 10%;
        border-radius: 24px;
        transform: rotate(-10deg);
    }

    .rom-img {
        width: 100%;
        position: relative;
    }

    .home-card-right {
        padding: 0;
        border-radius: 0 12px 12px 0;
        overflow: hidden;
    }

    .home-card-left {
        margin-top: 24px;
        margin-bottom: 32px;
        p {
                margin: 12px 0;
            }
        h4 {
            font-size: 16px;
            font-weight: 600;
            color: #000;
        }

        .project-context {
            display: flex;
            gap: 8px;
        }

        .dot:last-of-type {
            display: none;
        }
    }



    .comp-logo {
        .comp-section {
            display: flex;
            align-items: center;
            width: 100%;
            gap: 16px;

            h3 {
                font-size: 24px;
                margin: 0;
            }


        }

        .status {
            border: 1.5px solid var(--border);
            padding: 0px 8px;
            padding-right: 4px;
            border-radius: 100px;
            height: 28px;
            text-transform: uppercase;
            letter-spacing: 1%;
            display: flex;
            font-size: 13px;
            backdrop-filter: blur(10px);
            align-items: center;
            font-weight: 700;
            color: var(--text-secondary);

            &.lock {
                height: 28px;
                width: 28px;
                padding: 0px;
                display: flex;
                justify-content: center;
                margin-right: 8px;
            }

            &.lock ~ .status {
                padding-right: 8px;
            }

            img {
                height: 16px;
            }

            .shipped-arrow {
                opacity: 0.3;
                margin-left: 3px;
            }
        }

    }


}

.grad-blur-home {
    position: absolute;
    z-index: 1;
    inset: auto 0 0 0;
    height: 40%;
    width: 60%;
    top: 0;
    pointer-events: none;
}

.grad-blur-home>div,
.grad-blur-home::before,
.grad-blur-home::after {
    position: absolute;
    inset: 0;
}

.grad-blur-home::before {
    content: "";
    z-index: 1;
    backdrop-filter: blur(0.5px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 0%,
            rgba(0, 0, 0, 1) 12.5%,
            rgba(0, 0, 0, 1) 25%,
            rgba(0, 0, 0, 0) 37.5%);
}

.grad-blur-home>div:nth-of-type(1) {
    z-index: 2;
    backdrop-filter: blur(1px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 12.5%,
            rgba(0, 0, 0, 1) 25%,
            rgba(0, 0, 0, 1) 37.5%,
            rgba(0, 0, 0, 0) 50%);
}

.grad-blur-home>div:nth-of-type(2) {
    z-index: 3;
    backdrop-filter: blur(2px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 25%,
            rgba(0, 0, 0, 1) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 0) 62.5%);
}

.grad-blur-home>div:nth-of-type(3) {
    z-index: 4;
    backdrop-filter: blur(4px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 37.5%,
            rgba(0, 0, 0, 1) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 0) 75%);
}

.grad-blur-home>div:nth-of-type(4) {
    z-index: 5;
    backdrop-filter: blur(8px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 50%,
            rgba(0, 0, 0, 1) 62.5%,
            rgba(0, 0, 0, 1) 75%,
            rgba(0, 0, 0, 0) 87.5%);
}

.grad-blur-home>div:nth-of-type(5) {
    z-index: 6;
    backdrop-filter: blur(16px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 62.5%,
            rgba(0, 0, 0, 1) 75%,
            rgba(0, 0, 0, 1) 87.5%,
            rgba(0, 0, 0, 0) 100%);
}

.grad-blur-home>div:nth-of-type(6) {
    z-index: 7;
    backdrop-filter: blur(32px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 75%,
            rgba(0, 0, 0, 1) 87.5%,
            rgba(0, 0, 0, 1) 100%);
}

.grad-blur-home::after {
    content: "";
    z-index: 8;
    backdrop-filter: blur(64px);
    mask: linear-gradient(to top,
            rgba(0, 0, 0, 0) 87.5%,
            rgba(0, 0, 0, 1) 100%);
}


@media screen and (max-width: 1048px) {
    .home-card-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media screen and (max-width: 730px) {
    .home-card-container {
        grid-template-columns: repeat(1, 1fr);
    }
    .root-container {
        padding: 16px;
    }
}