body {
  font-family: "Manrope", sans-serif;
}

.texture {
  width: 100%;
  opacity: 0.7;
    height: 100%;
    position: fixed;
    background-size: cover;
    mix-blend-mode: overlay;
    z-index: 999999999;
    top: 0;
    pointer-events: none;
}

h1 {
  font-family: "neulis-cursive", sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 5vw;
}
h2 {
  font-family: "Manrope", sans-serif;
    font-weight: 800;
    font-style: normal;
    font-size: 5vw;
    color: rgba(0, 0, 0, 0.4);
}

.flex {
  display: flex;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-1 {
  flex: 1;
}

.flex-row {
  flex-direction: row;
}
.flex-column {
  flex-direction: column;
}
