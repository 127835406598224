.about {
    margin-top: 80px;
    margin-bottom: 96px;

    .about-container {
        max-width: 1440px;
    }

    h1 {
        font-size: 7vw;
        line-height: 90%;
    }
    h2 {
        font-size: 400%;
    }

    .socials {
        margin: 0px 0;
        margin-top: 16px;
        margin-left: -8px;

        img {
            width: 48px;
        }
    }

    .powers {
        border-top: 1px solid var(--border);
        padding-top: 64px;
    }
    

    .about-big {
        .active-social {
            display: flex;
            gap: 30px;
        }
        h4 a {
            color: #000;
        }
        p {
            font-size: 24px !important;
            line-height: 170%;
            color: var(--text-secondary);
            font-weight: 700;
        }


    }

    .exp-left {
        padding-top: 96px;
    }
    .exp-right {
        padding-top: 96px;
        border-top: 1px solid var(--border);
    }

    .about-small p {
        font-size: 18px;
        line-height: 170%;
        color: var(--text-secondary);
        font-weight: 600;
    }

    .about-small {
        h3 {
                color: #000;
                font-size: 18px;
                font-family: 'Manrope';
                margin: 0;
                font-weight: 800;
                margin-bottom: 8px;
            }
        h4 {
            font-size: 18px;
            font-weight: 900;
        }
    }
}

.about-pic-cont {
    height: 100%;
}

.about-me {
    width: 100%;
    padding-bottom: 150%;
    border-radius: 12px;
    background-size: cover;
    position: relative;
    background-position: center;

}
.mb-only {
    display: none;
}
.carousel-container {
    overflow: hidden;
    position: relative;
    height: 500px;
    width: 100%;
}

.carousel-card {
    display: flex;
    justify-content: center;
    align-items: center;

}

.carousel-card>img {
    width: auto;
    border-radius: 12px;
}

.carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    height: 80px;
    gap: 16px;
    width: 200%;
    height: 100%;
}


.carousel-track {
    display: flex;
    position: absolute;
    left: 0;
    justify-content: center;
    align-items: center;
    gap: 16px;
    width: 200%;
    height: 100%;
    animation: slide 20s linear infinite;
}

.about-big p:last-of-type {
    margin-bottom: 32px;
}

.radioContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    
        .radioButton {
            padding: 12px 16px;
                border-radius: 8px;
                margin: 8px;
                border: 2px solid #007BFF;
                background: #fff;
                display: flex;
                align-items: center;
                justify-content: center;
                width: 280px;
                cursor: pointer;
                transition: background-color 0.3s; 
                color: 0.3s;
                padding: 30px;
                box-shadow: 0px 2px 3.84px rgba(0, 0, 0, 0.25);
        }
    
        .selected {
            background: #007BFF;
            color: #fff;
            border-color: #007BFF;
        }
    
}


@keyframes slide {
    0% {
        left: 0;
    }

    100% {
        left: -100%;
    }
}

.experience {
    width: 100%;
    max-width: 1440px;
    padding: 0 32px !important;
    margin-top: 64px;
    margin-bottom: 64px;

    h1 {
        margin-bottom: 32px;
        font-size: 40px;
        font-family: 'manrope';
        font-weight: 900;
        color: #000 !important;
    }
    h3 {
        font-size: 18px;
        line-height: 150%;
        margin-bottom: 4px;
        color: rgba(0, 0, 0, 40%);
        font-weight: 800;
    }
    h3:first-of-type {
        color: var(--text);
    }

    .info {

        p {
            margin-bottom: 32px;
        }
        
        a {
            color: black;
            color: black;
            /* background: linear-gradient(to bottom, #0087ca 0%, #0087ca 100%); */
            background-position: 0 100%;
            background-repeat: repeat-x;
            background-size: 2px 2px;
            color: inherit;
            text-decoration: none;
            padding-right: 0 !important;
            padding-left: 0 !important;
            padding-bottom: 4px;
            margin-bottom: 2px;
        }
        
        a:hover {
        background-size: auto 4px;
    }
    }
}

.title-2 {
    color: #999999;
}

.image-mb {
    display: none;
}

.image-web {
    display: block;
}

@media screen and (min-width: 1600px) {
        .about {
            .about-small {
                h1 {
                    font-size: 103px;
                }
            }
        }

}
@media screen and (max-width: 991px) {

    .image-mb {
        display: block;

        .marquee {
            margin-bottom: 0 !important;
        }

        img {
            height: 400px;
        }
    }

    .exp-right {
        border: none;
    }
    
    .image-web {
        display: none !important;
    }


    .span-4 {
        &.about-big {
            grid-column: auto / span 12;
            margin-bottom: 64px;
        }
    }

    .featured {
        padding-top: 64px !important;
        h1 {
            margin-bottom: 64px;
        }
    }

    .powers {
        border: none !important;
        padding-top: 32px !important;
    }

    .about-me {
        width: 300px;
        padding-bottom: 30%;
    }

    .about-small {
                grid-column: auto / span 12 !important;
    }

    .experience {
        border-top: 1px solid var(--border);
                

        .span-2 {
            grid-column: auto / span 1;
        }
        .span-4 {
            grid-column: auto / span 12;
        }

        .span-6 {
            grid-column: auto / span 7;
        }

        .span-8 {
            grid-column: auto / span 12;
        }
    }
}

@media screen and (max-width: 868px) {
    .experience {
        .span-4 {
            grid-column: auto / span 12;
        }
        .span-8 {
            grid-column: auto / span 12;
        }
    }
}


@media screen and (max-width: 850px) {
    .about {
        margin-top: 80px;

        h1 {
            font-size: 10vw;
        }

        .about-big.span-5 {
            grid-column: auto / span 12;
        }

        .about-small.span-7 {
            grid-column: auto / span 12;
        }

        .about-me {
            height: 250px;
            width: 250px;
            padding: unset;
        }
    }
}


@media screen and (max-width: 694px) {
    .about {
        .about-small {
            .col-2-grid {
                display: block;
            }
        }
        .experience {
                h1 {
                    font-size: 40px !important;
                }
            }

        .about-small {
            .col-2-grid {
                p {
                    grid-column: auto / span 2;
                }
            }
        }

        .experience {
            .powers {
                div {
                grid-column: auto / span 2;
                }
            }
        }
    }
}


@media screen and (max-width: 636px) {
    .about {

        .about-big p:last-of-type {
            font-size: 20px !important;
            padding-bottom: 48px;
            margin-bottom: 48px;
            border-bottom: 1px solid var(--border);
        }
    }
}

@media screen and (max-width: 565px) {
    .about {
        .about-container {
            padding: 16px;
            padding-bottom: 64px;
        }
        .experience {
            padding-right: 16px !important;
            padding-left: 16px !important;
        }
        .about-big p:last-of-type {
            font-size: 18px !important;
        }

        .mb-only {
                display: inline-block;
        }

        .mb-only-date {
            display: flex;
            gap: 4px;
        }

        .exp-right, .powers {
            border: none;
            padding-top: 32px;
        }

        .experience {
            .span-2 h3 {
                display: none;
            }
        }
        .experience {
            .span-2 {
                grid-column: auto / span 12;
            }
            .span-6 {
                grid-column: auto / span 12;
            }
        }
    }
}