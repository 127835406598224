footer {
    background-color: #252525;
    padding: 32px;
    border-top: 1px solid rgba(255,255,255, 0.1);
    align-items: center;

    h4 {
        color: white;
        font-size: 16px;
    }

    img {
        filter: invert(1);
    }
}

