body {
  font-family: "Manrope", sans-serif;
  background-color: #F1F1EE;
}

.jumbotron {
  padding: 32px;
  width: 100%;
  margin: 0px auto;
  margin-top: 80px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
}

.jumbotron h3 {
  font-size: 24px;
  padding-bottom: 0px;
  margin-bottom: 0px;
  font-weight: 700;
  line-height: 150%;
}

.vblur {
  filter: url(#blur);
  opacity: 0.7;
  position: absolute;
  height: 70%;
  overflow: hidden;
  top: 0
}

.jumbotron h4 {
  font-size: 24px;
  line-height: 160%;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--text-secondary);
}

.left {
  padding: 48px 48px 48px 0;
  position: relative;
  grid-column: auto / span 2;
  border-right: 1px solid var(--border);
}

.right {
  padding: 48px 0px 48px 48px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.right .mee {
  margin-bottom: 20px;
}

.jumbotron h2 {
  padding-bottom: 20px;
  overflow: hidden;
  position: relative;
  margin-bottom: 0px;
}

.right h3:nth-last-of-type() {
  padding-top: 20px;
}

.job-section {
  margin-top: 20px;
}



a {
  text-decoration: none;
}

  
.home-card-img {
  width: 100%;
  align-self: flex-end;
}

.socials {
  margin-left: -8px;
  height: 48px;
  display: flex;
}

.socials a {
  height: 48px;
  display: inline-block;
}

@media screen and (max-width: 1370px) {
  .jumbotron {

  h1 {
    font-size: 500%;
  }
  h2 {
    font-size: 500%;
  }

  h3 {
    font-size: 20px;
  }
  h4 {
    font-size: 20px;
  }
  }
}
@media screen and (max-width: 1165px) {
  .jumbotron {

  h1 {
    font-size: 400%;
  }
  h2 {
    font-size: 400%;
  }
  }

  .grad-blur-home {
    height: 35% !important;
  }
}
/* @media screen and (max-width: 999px) {
  .jumbotron h1 {
    font-size: 40px;
  }
  .jumbotron h2 {
    font-size: 40px;
  }
} */
@media screen and (max-width: 991px) {
  .left {
      grid-column: auto / span 4;
      padding: 48px 0px;
      border: 0;

  }
  .right {
      border-top: 1px solid var(--border);
      grid-column: auto / span 4;
      padding: 48px 0;
  }
  .grad-blur-home {
    width: 100% !important;
  }
}

@media screen and (max-width: 868px) {

  .socials {
    margin-bottom: 20px;
  }
  .jumbotron .right {
    padding: 0;
    padding-top: 48px;
    border-top: 1px solid var(--border);
  }
  
}

@media screen and (max-width: 730px) {
  .jumbotron {
      padding: 16px
    }

}

@media screen and (max-width: 580px) {
  .jumbotron {
      h1 {
        font-size: 300%;
      }
  
      h2 {
        font-size: 300%;
      }
      h3 {
        font-size: 18px;
      }
      h4 {
        font-size: 18px;
      }
      
    }

    .grad-blur-home {
      height: 30% !important;
    }

}
@media screen and (max-width: 443px) {
  .jumbotron {
      h1 {
        font-size: 250%;
      }
  
      h2 {
        font-size: 250%;
      }
      h3 {
        font-size: 18px;
      }
      h4 {
        font-size: 18px;
      }
      
    }

    .grad-blur-home {
      height: 25% !important;
    }

}
@media screen and (max-width: 374px) {
  .jumbotron {
      h1 {
        font-size: 200%;
      }
  
      h2 {
        font-size: 200%;
      }
      h3 {
        font-size: 16px;
      }
      h4 {
        font-size: 16px;
        margin-bottom: 0;
      }
      
    }

    .grad-blur-home {
      height: 25% !important;
    }

}

